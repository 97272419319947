import React, {useState, useEffect} from 'react';
import line from "../../images/musica-liturgica/home/line.png";
import Divider from '@mui/material-next/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import axios from 'axios';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// import ptBr from 'date-fns/locale/pt-br';
// with date-fns v3.x
import { pt } from 'date-fns/locale/pt';

// baseUrl
import {baseUrl} from "../../services/adalConfig.js";

import "./Musica.css";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const temasOptions = {
    "entrada": "Entrada",
    "gloria": "Glória",
    "ato-penitencial": "Ato Penitencial",
    "salmo": "Salmo",
    "ofertorio": "Ofertório",
    "santo": "Santo",
    "amem": "Doxologia",
    "cordeiro-de-deus": "Cordeiro de Deus",
    "comunhao": "Comunhão",
    "nossa-senhora-aparecida": "Marianas",
    "natalinas": "Natalinas",
    "ladainhas": "Ladainhas"
}

const dictTemposLiturgicos = {
    1: "Todos os tempos",
    2: "Advento",
    3: "Quaresma",
    4: "Páscoa",
    5: "Tempo Comum"
};

export default function TempoLiturgico(props) {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [musicas, setMusicas] = useState([]);
    const [numeroMusicasMostrar, setNumeroMusicasMostrar] = useState({1: 5,2: 5,3: 5,4: 5,5: 5});
    const [tempoLiturgico, setTempoLiturgico] = React.useState(1);
    const [lingua, setLingua] = useState({"portugues": true, "latim": true});

    function handleAumentarItems(chave) {
        console.log("Entrou aqui");
        var tempItems = {...numeroMusicasMostrar};
        tempItems[chave] = tempItems[chave] + 5;
        setNumeroMusicasMostrar(tempItems);
    }
 
    useEffect(() => {
       function handleResize() {
         setWindowDimensions(getWindowDimensions());
       }
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);

    const temasOptions = {
    "entrada": "Entrada",
    "gloria": "Glória",
    "ato-penitencial": "Ato Penitencial",
    "salmo": "Salmo",
    "ofertorio": "Ofertório",
    "santo": "Santo",
    "amem": "Doxologia",
    "cordeiro-de-deus": "Cordeiro de Deus",
    "comunhao": "Comunhão",
    "nossa-senhora-aparecida": "Marianas",
    "natalinas": "Natalinas",
    "ladainhas": "Ladainhas"
}

    useEffect(() => {
        var url = window.location.href;

        var l = url.split("/");
        var parteMissa = l[l.length - 1];

        if (parteMissa == 'entrada') {
            parteMissa = 1;
        } else if (parteMissa == 'ato-penitencial') {
            parteMissa = 2;
        } else if (parteMissa == 'gloria') {
            parteMissa = 3;
        } else if (parteMissa == 'salmo') {
            parteMissa = 4;
        } else if (parteMissa == 'ofertorio') {
            parteMissa = 5;
        } else if (parteMissa == 'santo') {
            parteMissa = 6;
        } else if (parteMissa == 'amem') {
            parteMissa = 7;
        } else if (parteMissa == 'cordeiro-de-deus') {
            parteMissa = 8;
        } else if (parteMissa == 'comunhao') {
            parteMissa = 9;
        } else if (parteMissa == 'canto-final') {
            parteMissa = 10;
        } else if (parteMissa == 'ladainhas') {
            parteMissa = 11;
        } else if (parteMissa == 'natalinas') {
            parteMissa = 12;
        } else if (parteMissa == 'nossa-senhora-aparecida') {
            parteMissa = 13;
        } else {
            parteMissa = -1;
        }

        var getUrl = baseUrl + "/musicas-parte-missa?parteMissa="+parteMissa

        axios.get(getUrl)
            .then(resp => {
                setMusicas(resp.data);
            });
    }, []);

    var musicasAgora;
    console.log("Temp is");
    console.log(tempoLiturgico);
    if (tempoLiturgico == 1) {
        musicasAgora = musicas;
    } else if (tempoLiturgico == 2) {
        musicasAgora = musicas.filter(item => item.tempo_liturgico == tempoLiturgico);
    } else if (tempoLiturgico == 3) {
        musicasAgora = musicas.filter(item => item.tempo_liturgico == tempoLiturgico);
    } else if (tempoLiturgico == 4) {
        musicasAgora = musicas.filter(item => item.tempo_liturgico == tempoLiturgico);
    } else if (tempoLiturgico == 5) {
        musicasAgora = musicas.filter(item => item.tempo_liturgico == tempoLiturgico);
    }

    var tempMusicas = [];
    for (let i = 0; i < musicasAgora.length; i++ ) {
        if (lingua.portugues == true && musicasAgora[i].lingua == 1) tempMusicas.push(musicasAgora[i]);
        if (lingua.latim == true && musicasAgora[i].lingua == 2) tempMusicas.push(musicasAgora[i]);
    }

    var url = window.location.href;

    var l = url.split("/");
    var url_missa = l[l.length - 1];
 
    if (window.innerWidth <= 768) {
       return (
        <TempoLiturgicoHomeMobile 
            musicas={tempMusicas} 
            numeroMusicasMostrar={numeroMusicasMostrar} 
            handleAumentarItems={handleAumentarItems} 
            url_missa={url_missa} 
            tempoLiturgico={tempoLiturgico} 
            setTempoLiturgico={setTempoLiturgico}
            lingua={lingua}
            setLingua={setLingua}
        />);
    } else {
       return (
        <TempoLiturgicoHomeDesktop 
            musicas={tempMusicas} 
            numeroMusicasMostrar={numeroMusicasMostrar} 
            handleAumentarItems={handleAumentarItems} 
            url_missa={url_missa} 
            tempoLiturgico={tempoLiturgico} 
            setTempoLiturgico={setTempoLiturgico}
            lingua={lingua}
            setLingua={setLingua}
        />);
    }
}

function TempoLiturgicoHomeDesktop(props) {
    
    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
            <div class="musica-home-header" style={{marginTop: "20px"}}>
                <Divider sx={{
                    "&::before, &::after": {
                    borderColor: "#bc1200",
                    },
                }}>
                    {temasOptions[props.url_missa]}
                </Divider>
            </div>
            <div style={{width: "70%", display: "flex", alignItems: "center", justifyContent: "space-around", marginTop: "45px", marginBottom: "0px"}}>
                <Button onClick={() => props.setTempoLiturgico(1)} style={props.tempoLiturgico == 1? {backgroundColor: "#bc1200"}: {backgroundColor: "black"}} variant="contained">{dictTemposLiturgicos[1]}</Button>
                <Button onClick={() => props.setTempoLiturgico(2)} style={props.tempoLiturgico == 2? {backgroundColor: "#bc1200"}: {backgroundColor: "black"}} variant="contained">{dictTemposLiturgicos[2]}</Button>
                <Button onClick={() => props.setTempoLiturgico(3)} style={props.tempoLiturgico == 3? {backgroundColor: "#bc1200"}: {backgroundColor: "black"}} variant="contained">{dictTemposLiturgicos[3]}</Button>
                <Button onClick={() => props.setTempoLiturgico(4)} style={props.tempoLiturgico == 4? {backgroundColor: "#bc1200"}: {backgroundColor: "black"}} variant="contained">{dictTemposLiturgicos[4]}</Button>
                <Button onClick={() => props.setTempoLiturgico(5)} style={props.tempoLiturgico == 5? {backgroundColor: "#bc1200"}: {backgroundColor: "black"}} variant="contained">{dictTemposLiturgicos[5]}</Button>
                <div>
                    <p style={{marginBottom: "10px", fontSize: "20px"}}>Língua</p>
                    <FormGroup >
                        <FormControlLabel 
                            style={{height: "30px"}} 
                            control={  <Checkbox sx={{
                                                        color: "#bc1200",
                                                        '&.Mui-checked': {
                                                        color: "#bc1200",
                                                        },
                                                    }} 
                                                    defaultChecked
                                                    checked={props.lingua.portugues}
                                                    onChange={(event) => props.setLingua({...props.lingua, portugues: event.target.checked})}
                                                    />} 
                            label="Português" />
                        <FormControlLabel  
                            style={{height: "30px"}} 
                            control={  <Checkbox sx={{
                                                        color: "#bc1200",
                                                        '&.Mui-checked': {
                                                        color: "#bc1200",
                                                        },
                                                    }} 
                                                    defaultChecked
                                                    onChange={(event) => props.setLingua({...props.lingua, latim: event.target.checked})}
                                                    />} 
                            label="Latim" />
                    </FormGroup>
                </div>
            </div>
            <div style={{marginBottom: "0px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "45px", overflow: "hidden", width: "60%"}}>
                <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                    {
                        props.musicas.slice(0, props.numeroMusicasMostrar[props.tempoLiturgico]).map((item) =>
                          <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                        )
                    }
                </ul>
                <p onClick={() => props.handleAumentarItems(props.tempoLiturgico)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "fit-content"}}><b>Ver mais </b></p> 
            </div>
        </div>);

}

function TempoLiturgicoHomeMobile(props) {
    
    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
            <div style={{width: "90%", marginTop: "0px"}}>
                    <div class="musica-home-header" style={{width: "100%", marginTop: "0px"}}>
                        <Divider sx={{
                            "&::before, &::after": {
                            borderColor: "#bc1200",
                            },
                        }}>
                            {temasOptions[props.url_missa]}
                        </Divider>
                    </div>
            </div>
            <div style={{width: "90%", display: "flex", alignItems: "center", justifyContent: "space-around", marginTop: "25px", marginBottom: "0px"}}>
                <FormControl fullWidth style={{width: "50%"}}>
                    <InputLabel id="demo-simple-select-label">Tempo Litúrgico</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={props.tempoLiturgico}
                      label="Tempo Litúrgico"
                      onChange={(event) => props.setTempoLiturgico(event.target.value)}
                    >
                        <MenuItem value={1}>{dictTemposLiturgicos[1]}</MenuItem>
                        <MenuItem value={2}>{dictTemposLiturgicos[2]}</MenuItem>
                        <MenuItem value={3}>{dictTemposLiturgicos[3]}</MenuItem>
                        <MenuItem value={4}>{dictTemposLiturgicos[4]}</MenuItem>
                        <MenuItem value={5}>{dictTemposLiturgicos[5]}</MenuItem>
                    </Select>
                </FormControl>
                <div style={{width: "30%"}}>
                    <p style={{marginBottom: "10px", fontSize: "20px"}}>Língua</p>
                    <FormGroup >
                        <FormControlLabel 
                            style={{height: "30px"}} 
                            control={  <Checkbox sx={{
                                                        color: "#bc1200",
                                                        '&.Mui-checked': {
                                                        color: "#bc1200",
                                                        },
                                                    }} 
                                                    defaultChecked
                                                    checked={props.lingua.portugues}
                                                    onChange={(event) => props.setLingua({...props.lingua, portugues: event.target.checked})}
                                                    />} 
                            label="Português" />
                        <FormControlLabel  
                            style={{height: "30px"}} 
                            control={  <Checkbox sx={{
                                                        color: "#bc1200",
                                                        '&.Mui-checked': {
                                                        color: "#bc1200",
                                                        },
                                                    }} 
                                                    defaultChecked
                                                    onChange={(event) => props.setLingua({...props.lingua, latim: event.target.checked})}
                                                    />} 
                            label="Latim" />
                    </FormGroup>
                </div>
            </div>
            <div style={{marginBottom: "0px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "25px", overflow: "hidden", width: "90%"}}>
                <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                    {
                        props.musicas.slice(0, props.numeroMusicasMostrar[props.tempoLiturgico]).map((item) =>
                          <li className="musica-page-musica-item"><NavLink to={"/musica-liturgica/musica/"+item.url} style={{color: "black", textDecoration: "none"}}>{item.nome} - {item.autor}</NavLink></li>
                        )
                    }
                </ul>
                <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
                    <li onClick={() => props.handleAumentarItems(props.tempoLiturgico)} style={{    marginBottom: "0px", marginTop: "0px", color: "#bc1200", width: "100%", textAlign: "start", listStyleType: "none"}}>
                        <b>Ver mais </b>
                    </li>
                </ul>
            </div>
        </div>);
}