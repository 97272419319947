import {useState, useEffect} from 'react';
import Divider from '@mui/material-next/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';

import Musicas from "./PaginasCadastro/Musicas";
import Missas from "./PaginasCadastro/Missas";
import CalendarioMissas from "./PaginasCadastro/CalendarioMissas";
import Oracoes from "./PaginasCadastro/Oracoes";
import TiposOracoes from "./PaginasCadastro/TiposOracoes";
import Subsidios from "./PaginasCadastro/Subsidios";
import CadastroArtigos from "./PaginasCadastro/CadastroArtigos";

import "./CadastroDados.css";

// baseUrl
import {baseUrl} from "../../services/adalConfig.js";

export default function Home(props) {

	const [open, setOpen] = useState(false);
	const [titulo, setTitulo] = useState();
	const [alertDict, setAlertDict] = useState({open: false, texto: "", severidade: ""});
	const [isUpdateMode, setIsUpdateMode] = useState(false);
	const [isEditMode, setIsEditMode] = useState(true);

	const handleClose = () => {
		setAlertDict({open: false, texto: "", severidade: ""});
	}

	const cadastrosDict = {
		"Músicas": <Musicas token={props.token} setAlertDict={setAlertDict} isEditMode={isEditMode} setIsEditMode={setIsEditMode} isUpdateMode={isUpdateMode} setIsUpdateMode={setIsUpdateMode}/>, 
		"Missas": <Missas token={props.token} setAlertDict={setAlertDict} isEditMode={isEditMode} setIsEditMode={setIsEditMode} isUpdateMode={isUpdateMode} setIsUpdateMode={setIsUpdateMode}/>,  
		"Calendário de Missas": <CalendarioMissas token={props.token} setAlertDict={setAlertDict} isEditMode={isEditMode} setIsEditMode={setIsEditMode} isUpdateMode={isUpdateMode} setIsUpdateMode={setIsUpdateMode}/>,
		"Tipos de Orações": <TiposOracoes token={props.token} setAlertDict={setAlertDict} isEditMode={isEditMode} setIsEditMode={setIsEditMode} isUpdateMode={isUpdateMode} setIsUpdateMode={setIsUpdateMode}/>,
		"Orações": <Oracoes token={props.token} setAlertDict={setAlertDict} isEditMode={isEditMode} setIsEditMode={setIsEditMode} isUpdateMode={isUpdateMode} setIsUpdateMode={setIsUpdateMode}/>,
		"Subsídios": <Subsidios token={props.token} setAlertDict={setAlertDict} isEditMode={isEditMode} setIsEditMode={setIsEditMode} isUpdateMode={isUpdateMode} setIsUpdateMode={setIsUpdateMode}/>,
		"Cadastro de Artigos": <CadastroArtigos token={props.token} setAlertDict={setAlertDict} isEditMode={isEditMode} setIsEditMode={setIsEditMode} isUpdateMode={isUpdateMode} setIsUpdateMode={setIsUpdateMode}/>
	};

	const changePage = (newTitle) => {
		setTitulo(newTitle);
	}

	const cards = Object.keys(cadastrosDict).map((item, index) => {
		return (
			<div className="cadastro-dados-card-container">
				<div className="cadastro-dados-card" onClick={() => changePage(item)}>
					<p>{item}</p>
				</div>
			</div>
		)
	})

	const handleComeBack = () => {
		setTitulo(null);
		setIsEditMode(true);
		setIsUpdateMode(false);
	}

	useEffect(() => {
		console.log("Palhacada");
	}, [isEditMode]);

	return (
		<>
			<Snackbar open={alertDict["open"]} autoHideDuration={6000} onClose={handleClose}>
		        <Alert
		          onClose={handleClose}
		          severity={alertDict["severidade"]}
		          variant="filled"
		          sx={{ width: '100%' }}
		        >
		         	{alertDict["texto"]}
		        </Alert>
		     </Snackbar>
			<div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}} id="div-master-cadastros">
				<div class="musica-home-header" style={{marginBottom: "40px"}}>
	                <Divider sx={{
	                    "&::before, &::after": {
	                    borderColor: "#bc1200",
	                    },
	                }}>
	                	<div style={{display: "flex", gap: "35px"}}>
		                	<Button style={titulo == null? {visibility: "hidden"} : {}} onClick={() => handleComeBack()}><ArrowBackIcon/><p style={{marginLeft: "10px"}}>Voltar</p></Button>
		                	{titulo? titulo : "Cadastro Dados"}
		                	{
		                		(isEditMode == false)?
		                		<Button style={titulo == null? {visibility: "hidden"} : {}} onClick={() => setIsEditMode(!isEditMode)}><SettingsIcon/><p style={{marginLeft: "10px"}}>Editar</p></Button>
		                		:
		                		((isUpdateMode == false)?
		                		<Button style={titulo == null? {visibility: "hidden"} : {}} onClick={() => setIsEditMode(!isEditMode)}><AddIcon/><p style={{marginLeft: "10px"}}>Adicionar</p></Button>
		                		:
		                		<Button style={{visibility: "hidden"}} onClick={() => setIsEditMode(!isEditMode)}><AddIcon/><p style={{marginLeft: "10px"}}>Adicionar</p></Button>)
		                	}
		                	
		                </div>
	                </Divider>
	            </div>
	            {
	            	(titulo != null)
	            	?
	            	cadastrosDict[titulo]
	            	:
	            	<div id="div-cards-home-cadastro-dados">
		            	{cards}
		            </div>
	            } 
			</div>
		</>
		);
}