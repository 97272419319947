import {useState, useEffect} from 'react';

import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material-next/Divider';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";

// baseUrl
import {baseUrl} from "../../../services/adalConfig.js";

const dictTemposLiturgicos = {
	1: 'todos-os-tempos',
	2: 'advento',
	3: 'quaresma',
	4: 'pascoa',
	5: 'tempo-comum'
}

export default function Musicas(props) {

	const [valuesDict, setValuesDict] = useState({nome: "", autor: "", cifra: "(D G D C){Introdução}G{SENHOR NOSSO }D{DE}G{US,\\n} D{REUNI VOSSOS FILHOS }G{DISPERSOS PELO }D{MUN}D7{DO,\\n}(G D F){Ponte}", parteMissa: "", url_youtube: "", link_partitura: "", lingua: 1, tempo_liturgico: 1});
	const [missasQuePossuemEstaMusica, setMissasQuePossuemEstaMusica] = useState([]);
	const [missas, setMissas] = useState({'tempo-comum': {}, 'pascoa': {}, 'quaresma': {}, 'advento': {}, 'tempo-comum': {}, 'todos-os-tempos': {}});
	const [check, setCheck] = useState({url_youtube: false, link_partitura: false});
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const changeValues = (field, newValue) => {
		console.log("oi");
		var tempDict = valuesDict;

		tempDict = {...tempDict, [field]: newValue};
		console.log(tempDict);
		setValuesDict(tempDict);
	}

	async function handleEditRow(rowDict) {

		var tempParteMissa;
		if (rowDict.parteMissa == "Entrada") {
			tempParteMissa = 1;
		} else if (rowDict.parteMissa == "Ato Penitencial") {
			tempParteMissa = 2;
		} else if (rowDict.parteMissa == "Glória") {
			tempParteMissa = 3;
		} else if (rowDict.parteMissa == "Salmo Responsorial") {
			tempParteMissa = 4;
		} else if (rowDict.parteMissa == "Ofertório") {
			tempParteMissa = 5;
		} else if (rowDict.parteMissa == "Santo") {
			tempParteMissa = 6;
		} else if (rowDict.parteMissa == "Doxologia") {
			tempParteMissa = 7;
		} else if (rowDict.parteMissa == "Cordeiro de Deus") {
			tempParteMissa = 8;
		} else if (rowDict.parteMissa == "Comunhão") {
			tempParteMissa = 9;
		} else if (rowDict.parteMissa == "Canto Final") {
			tempParteMissa = 10;
		} else if (rowDict.parteMissa == "Ladainhas") {
			tempParteMissa = 11;
		} else if (rowDict.parteMissa == "Natalinas") {
			tempParteMissa = 12;
		} else if (rowDict.parteMissa == "Nossa Senhora Aparecida") {
			tempParteMissa = 13;
		}

		var tempTempoLiturgico;
		if (rowDict.tempo_liturgico == "Todos os tempos") {
			tempTempoLiturgico = 1;
		} else if (rowDict.tempo_liturgico == "Advento") {
			tempTempoLiturgico = 2;
		} else if (rowDict.tempo_liturgico == "Quaresma") {
			tempTempoLiturgico = 3;
		} else if (rowDict.tempo_liturgico == "Páscoa") {
			tempTempoLiturgico = 4;
		} else if (rowDict.tempo_liturgico == "Tempo Comum") {
			tempTempoLiturgico = 5;
		}

		var tempLingua;
		if (rowDict.lingua == "Português") {
			tempLingua = 1;
		} else if (rowDict.lingua == "Latim") {
			tempLingua = 2;
		}

		var getUrl = baseUrl + "/get-missas-da-musica?idMusica="+rowDict.id;

        await axios.get(getUrl)
        	.then(resp => {
        		setMissasQuePossuemEstaMusica(resp.data)
        	});

        console.log("Row dict is");
        console.log(rowDict);

		setValuesDict({...rowDict, lingua: tempLingua, tempo_liturgico: tempTempoLiturgico, parteMissa: tempParteMissa, cifra: rowDict.cifra.replaceAll("\r", "\\n").replaceAll("\n", "\\n").replaceAll("\n\n","")});
		setCheck({url_youtube: rowDict.flag_youtube == 0? false : true, link_partitura: rowDict.flag_partitura == 0? false : true});
		props.setIsUpdateMode(true);
	}

	function cancelEditRow() {
		setValuesDict({nome: "", autor: "", cifra: "(D G D C){Introdução}G{SENHOR NOSSO }D{DE}G{US,\\n} D{REUNI VOSSOS FILHOS }G{DISPERSOS PELO }D{MUN}D7{DO,\\n}(G D F){Ponte}", parteMissa: "", url_youtube: "", link_partitura: "", lingua: 1, tempo_liturgico: 1});
		props.setIsUpdateMode(false);
		atualizaDados();
	}

	function generateCifra(cifra) {

		if (cifra == "") {
			return <></>;
		}

		try {
			var tempCifra = cifra.split("}");

			for (let i = 0; i < tempCifra.length - 1; i++) {
				tempCifra[i] = tempCifra[i] + "}";
			}

			var tempList = [];

			const regexAcorde = /^.*?(?=\{)/;
			const regexLetra = /(?<=\{).*?(?=\})/;
			var numeroNovasLinhas;

			for (let i = 0; i < tempCifra.length - 1; i++) {

				const acorde = tempCifra[i].match(regexAcorde)[0];
				var letra = tempCifra[i].match(regexLetra)[0];

				const matches = letra.match(/\\n/g);

				const quantidadePularLinha = matches ? matches.length : 0;

				letra = letra.replaceAll("\\n", "");

				var primeiroCaractere = acorde;

				if (primeiroCaractere.substring(0,1) == "(") {

					var descricaoPassagem = letra;
					var acordePassagem = acorde.substring(1, acorde.length-1).replaceAll(' ', "   ");

					var element = <>
								<span className="musicas-page-bloco-cifra-letra" style={{flexDirection: "row", width: "100%", alignItems: "center"}}>
									<p className="musicas-page-letra-musica" style={{fontSize: "15px"}}>{descricaoPassagem}:</p>
									<span>&nbsp;&nbsp;</span>
									<p className="musicas-page-acorde" style={{fontSize: "15px"}}>{acordePassagem}</p>
								</span>
							<br/>
						</>;
				} else {
					if (letra == "\\l") {
						var element = <Divider style={{width: "100%", margin: "20px 0"}} sx={{
	                    borderColor: "#bc1200"
	                    }} />
					} else {
						letra = letra.split(/(<strong>.*?<\/strong>)/).map(segment => {
		                    if (segment.includes('strong')) {
		                    const text = segment.replace('<strong>','').replace('</strong>', '');
		                    return <strong>{text}</strong>;
		                    } else {
		                    return segment;
		                    }
	                    });
	                    var element = 
						<>
							<span className="musicas-page-bloco-cifra-letra">
								<h6 className="musicas-page-acorde" style={acorde == "*" ?{visibility: "hidden", fontSize: "15px"}: {fontSize: "15px"}}>{acorde}</h6>
								<h6 className="musicas-page-letra-musica" style={{fontSize: "15px"}}>{letra}</h6>
							</span>
							{Array(quantidadePularLinha).fill(<div style={{width: "100%"}}></div>)}
						</>;
					}
					
				}
				tempList.push(element);
			}

			console.log("returning");
			console.log(tempList);

			if (tempList.length == 0) {
				return <p style={{color: "red"}}>Erro de compilação</p>
			}

			return tempList;
		} catch (error) {
			console.log("error is");
			console.log(error);
			return <p style={{color: "red"}}>Erro de compilação</p>
		}

	}

	async function submitForm() {

		if (props.isUpdateMode) {
			var postUrl = baseUrl + "/update-musica";
		} else {
			var postUrl = baseUrl + "/cadastrar-musica";
		}
		
		var payload = valuesDict;

		var emptyValues = [];
		var payloadKeys = Object.keys(payload);
		for (let i = 0; i < payloadKeys.length; i++) {
			if (payloadKeys[i] != "flag_youtube" && payloadKeys[i] != "flag_partitura" && (payload[payloadKeys[i]] == "" || payload[payloadKeys[i]] == null)) {
				if (payloadKeys[i] == "url_youtube" && check["url_youtube"] == false) continue;
				else if (payloadKeys[i] == "link_partitura" && check["link_partitura"] == false) continue;
				else emptyValues.push(payloadKeys[i]);
			}
		}

		if (emptyValues.length > 0) {
			console.log("There are empty values:");
			console.log(emptyValues.join(", "));
			props.setAlertDict({open: true, texto: `Os seguintes campos estão vazios: ${emptyValues.join(", ")}`, severidade: "error"});
			return;
		}

		
		var originalUrl;
		if (props.isUpdateMode) {
			originalUrl = payload["url"];
		}

		payload["url"] = payload["nome"].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().replaceAll("/", "").replaceAll("  ", " ").replaceAll("\n", "").replaceAll("\r", "-").replaceAll(" , ", "-").replaceAll(" ,", "-").replaceAll(", ", "-").replaceAll(" ", "-").replaceAll(",", "-").replaceAll(";", "-") + "-" + payload["autor"].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().replaceAll("/", "").replaceAll("  ", " ").replaceAll("\n", "").replaceAll("\r", "-").replaceAll(" , ", "-").replaceAll(" ,", "-").replaceAll(", ", "-").replaceAll(" ", "-").replaceAll(",", "-").replaceAll(";", "-");

		if (check["url_youtube"] == false) {
			payload["url_youtube"] = "";
		}

		if (check["link_partitura"] == false) {
			payload["link_partitura"] = "";
		}

		payload["token"] = props.token;

		payload["cifra"] = payload["cifra"].replaceAll("\l", "\\l");

		console.log("Payload is");
		console.log(payload);

		setLoading(true);

		await axios.post(postUrl, payload)
			.then(resp => {
				console.log("Bach is cool");
				console.log(resp.data);
				//setLoading(false);
				//props.setAlertDict({open: true, texto: "Sucesso ao inserir nova música!", severidade: "success"});
            })
            .catch((exp) => {
            	setLoading(false);
				props.setAlertDict({open: true, texto: "Erro ao inserir nova música.", severidade: "error"});
            });

		var getUrl = baseUrl + "/dados-musica?url="+payload["url"];

        axios.get(getUrl)
        	.then(resp => {
        		if (resp.data.id != null) {
        			postMusicasPorMissa(resp.data.id)
        		}
        		;
        	});
	}

	async function postMusicasPorMissa(idMusica) {

		var postUrl = baseUrl + "/cadastrar-musicas-por-missa";

		var arrayIds = [];
		for (let i = 0; i < missasQuePossuemEstaMusica.length; i++) {
			arrayIds.push(missasQuePossuemEstaMusica[i].id);
		}

		var payload = {
			array_id_missa: arrayIds,
			id_musica: idMusica
		};

		await axios.post(postUrl, payload)
			.then(resp => {
				console.log("Bach is cool");
				console.log(resp.data);
				setLoading(false);
				props.setAlertDict({open: true, texto: "Sucesso ao inserir nova música!", severidade: "success"});
            })
            .catch((exp) => {
            	setLoading(false);
				props.setAlertDict({open: true, texto: "Erro ao inserir nova música.", severidade: "error"});
            });

        if (props.isUpdateMode) cancelEditRow();
	}

	function checkingHandler(field, newValue) {
		setCheck({...check, [field]: newValue});
	}

	const getOptions = () => {
		var tempDict = {};
		if (valuesDict["tempo_liturgico"] == 1) {
			tempDict = {...missas['tempo-comum'], ...missas['pascoa'], ...missas['quaresma'], ...missas['advento'], ...missas['todos-os-tempos']};
		} else {
			tempDict = missas[dictTemposLiturgicos[valuesDict["tempo_liturgico"]]];
		}
		var tempList = [];
		for (const item in tempDict) {
		    tempList.push({"id": tempDict[item], "nome": item});
		}
		if (tempList == undefined || tempList == null) return [];
		else return tempList;
	}

	const removeDictsListDuplicates = (newValue) => {
		var tempFinalList = [];
		var currentIds = [];
		for (let i = 0; i < newValue.length; i++) {
			if (currentIds.includes(newValue[i].id) == false) {
				tempFinalList.push(newValue[i]);
				currentIds.push(newValue[i].id);
			}
		}
		console.log("formato");
		console.log(tempFinalList);
		setMissasQuePossuemEstaMusica(tempFinalList);
	}

	const columns = [
    	{
			field: 'nome',
		    headerName: 'Nome',
		    width: 140,
		    flex: 1
    	},
    	{
    		field: 'autor',
		    headerName: 'Autor',
		    width: 140,
		    flex: 1
    	}, 
    	{
    		field: 'url',
		    headerName: 'URL',
		    width: 140,
		    flex: 1,
		    renderCell: (params) => <a href={"/musica-liturgica/musica/" + params.row.url} target="_blank">{params.row.url}</a>
    	}, 
    	{
    		field: 'parteMissa',
		    headerName: 'Parte da Missa',
		    width: 140,
    	},
    	{
    		field: 'url_youtube',
		    headerName: 'URL do YouTube',
		    width: 140,
		    flex: 1,
		    renderCell: (params) => <a href={params.row.url_youtube} target="_blank">{params.row.url_youtube}</a>
    	}, 
    	{	
    		field: 'link_partitura',
		    headerName: 'URL da Partitura',
		    width: 140,
		    flex: 1,
		    renderCell: (params) => <a href={params.row.link_partitura} target="_blank">{params.row.link_partitura}</a>
    	}, 
    	{
    		field: 'lingua',
		    headerName: 'Língua',
		    width: 100,
    	}, 
    	{
    		field: 'tempo_liturgico',
		    headerName: 'Tempo Litúrgico',
		    width: 140
		},
		{
	        field: 'actions',
	        type: 'actions',
	        width: 80,
	        getActions: (params) => [
	          	<EditIcon
	          		onClick={() => handleEditRow(params.row)}
	          	/>,
	        ],
	    }
    ];

    function atualizaDados() {
    	var getUrl = baseUrl + "/get-musicas";

        axios.get(getUrl)
            .then(resp => { 
                setRows(resp.data); 
            });
    }

	useEffect(() => {

        var getUrl = baseUrl + "/get-tempos-liturgicos-with-id";

        axios.get(getUrl)
            .then(resp => { 
                setMissas(resp.data); 
            });
    }, []);

    useEffect(() => {

    	console.log("New value");
    	console.log(props.isEditMode);

    	if (props.isEditMode == true || rows.length == 0) {
    		atualizaDados();
    	}

    }, [props.isEditMode]);

    useEffect(() => {
    	if (props.isUpdateMode == false) {
    		cancelEditRow();
    	}
    }, [props.isUpdateMode]);

    if (props.isEditMode == true && props.isUpdateMode == false) {
    	return (
    		<DataGrid
    			autoHeight 
		        rows={rows}
		        columns={columns}
		        pageSizeOptions={[50]}
		        style={{width: "85%"}}
		    />
    	);
    } else
		return (
		<form noValidate onSubmit={() => console.log("oi turma")} id="formulario-login-cadastro-dados">
			<TextField
				inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
  				InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input
	          	label="Nome da música *"
	          	value={valuesDict["nome"]}
				onChange={(event) => {
				    changeValues("nome", event.target.value);
				}}
	        />
	        <TextField
	        	inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
  				InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input
	          	label="Autor da música *"
	          	value={valuesDict["autor"]}
				onChange={(event) => {
				    changeValues("autor", event.target.value);
				}}
	        />
	        <FormControl fullWidth>
		        <InputLabel style={{fontFamily: "arial"}} id="demo-simple-select-label">Parte da Missa *</InputLabel>
		        <Select 
		          style={{fontFamily: "arial"}}
		          labelId="demo-simple-select-label"
		          id="demo-simple-select"
		          value={valuesDict["parteMissa"]}
		          label="Parte da Missa"
		          onChange={(event) => changeValues("parteMissa", event.target.value)}
		        >
			        <MenuItem style={{fontFamily: "arial"}} value={1}>Entrada</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}} value={2}>Ato Penitencial</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}} value={3}>Glória</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}} value={4}>Salmo Responsorial</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}} value={5}>Ofertório</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}} value={6}>Santo</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}} value={7}>Doxologia</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}} value={8}>Cordeiro de Deus</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}} value={9}>Comunhão</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}} value={10}>Canto Final</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}} value={11}>Ladainhas</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}} value={12}>Natalinas</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}} value={13}>Nossa Senhora Aparecida</MenuItem>
		        </Select>
		    </FormControl>
	        <FormControl fullWidth>
		        <InputLabel style={{fontFamily: "arial"}}  id="demo-simple-select-label">Tempo Litúrgico *</InputLabel>
		        <Select 
		          style={{fontFamily: "arial"}} 
		          labelId="demo-simple-select-label"
		          id="demo-simple-select"
		          value={valuesDict["tempo_liturgico"]}
		          label="Parte da Missa"
		          onChange={(event) => changeValues("tempo_liturgico", event.target.value)}
		        >
			        <MenuItem style={{fontFamily: "arial"}}  value={1}>Todos os Tempos</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}}  value={2}>Advento</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}}  value={3}>Quaresma</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}}  value={4}>Páscoa</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}}  value={5}>Tempo Comum</MenuItem>
		        </Select>
		    </FormControl>
		    <p>Dica: Segure Ctrl para poder selecionar várias músicas sem fechar a aba de seleção</p>
		    <Autocomplete
				multiple
				id="fixed-tags-demo"
				value={missasQuePossuemEstaMusica}
				onChange={(event, newValue) => {
					removeDictsListDuplicates(newValue);
				}}
				options={getOptions()}
				getOptionLabel={(option) => option.nome}
				style={{ width: "100%" }}
				renderInput={(params) => (
					<TextField {...params} 
						label="Missas que possuem esta música" />
				)}
			/>
		    <FormControl fullWidth>
		        <InputLabel style={{fontFamily: "arial"}}  id="demo-simple-select-label">Língua *</InputLabel>
		        <Select 
		          style={{fontFamily: "arial"}} 
		          labelId="demo-simple-select-label"
		          id="demo-simple-select"
		          value={valuesDict["lingua"]}
		          label="Língua da música"
		          onChange={(event) => changeValues("lingua", event.target.value)}
		        >
			        <MenuItem style={{fontFamily: "arial"}}  value={1}>Português</MenuItem>
			        <MenuItem style={{fontFamily: "arial"}}  value={2}>Latim</MenuItem>
		        </Select>
		    </FormControl>
		    <div style={{width: "100%"}}>
		    	<FormGroup>
		      		<FormControlLabel control={<Checkbox checked={check["url_youtube"]} onChange={(event) => checkingHandler("url_youtube", event.target.checked)}/>} label="Possui link do Youtube?" />
			    </FormGroup>
			    {
			    	(check["url_youtube"])
			    	&&
			    	<TextField
			    		inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
  						InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input
			          	label="Url do Youtube"
			          	value={valuesDict["url_youtube"]}
						onChange={(event) => {
						    changeValues("url_youtube", event.target.value);
						}}
						style={{width: "100%"}}
			        />
			    }
		    </div>
		    <div style={{width: "100%"}}>
		    	<FormGroup>
		      		<FormControlLabel control={<Checkbox checked={check["link_partitura"]} onChange={(event) => checkingHandler("link_partitura", event.target.checked)}/>} label="Possui link de partitura?" />
			    </FormGroup>
			    {
			    (check["link_partitura"])
			    &&
			    <TextField
			    	inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
  					InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input
		          	label="Link da partitura"
		          	value={valuesDict["link_partitura"]}
					onChange={(event) => {
					    changeValues("link_partitura", event.target.value);
					}}
					style={{width: "100%"}}
		        />
			    }
		    </div>
	        <div style={{width: "100%"}}>
		        <a href="https://docs.google.com/document/d/1yz8gQ9FTOMakVWY84pUsn8nACueV_JBBdG5kayGih9g/edit?usp=sharing" target="_blank" className="link-cadastro-dados">Acesse o manual para preenchimento da cifra</a>
		        <TextField
		        	inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
  					InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input
		          	label="Cifra *"
		          	value={valuesDict["cifra"]}
					onChange={(event) => {
					    changeValues("cifra", event.target.value);
					}}
					multiline
					style={{marginTop: "10px", width: "100%"}}
		        />
		        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "70px"}}>
		        	<KeyboardDoubleArrowDownIcon/>
		        	<p>Como ficará no site</p>
		        	<KeyboardDoubleArrowDownIcon/>
		        </div>
		        <div style={{display: "flex", flexWrap: "wrap"}}>
		        	{generateCifra(valuesDict["cifra"])}
		        </div>
		    </div>
		    <LoadingButton loading={loading} onClick={() => submitForm()} style={{backgroundColor: "#bc1200"}} variant="contained">
		    	Salvar dados
		    </LoadingButton>
		</form>
	);
}