	// App.js
	import { lazy, Suspense, useEffect, useState } from 'react';
	import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

	import NavBar from './components/NavBar';
	import Footer from './components/Footer';

	import Home from './pages/Home';
	import MusicaLiturgicaHome from './pages/Musica/MusicaLiturgicaHome';
	import TempoLiturgico from './pages/Musica/TempoLiturgico';
	import MusicaPage from './pages/Musica/MusicaPage';
	import MusicaCifra from './pages/Musica/MusicaCifra';
	import TemaMusica from './pages/Musica/TemaMusica';
	import HomeOracoes from './pages/Oracoes/HomeOracoes';
	import TemaOracoes from './pages/Oracoes/TemaOracoes';
	import Oracao from './pages/Oracoes/Oracao';
	import HomeSubsidios from './pages/Subsidios/HomeSubsidios';
	import Login from './pages/CadastroDados/Login';
	import HomeCadastro from './pages/CadastroDados/Home';
	import HomeArtigos from './pages/Artigos/HomeArtigos';
	import LerArtigo from './pages/Artigos/LerArtigo';
	//import CadastroDados from './pages/CadastroDados/CadastroDados';

	import { isAuthenticated } from "./services/auth";

	const App = () => {

	const [token, setToken] = useState("");//const [token, setToken] = useState("");
	const [temAcesso, setTemAcesso] = useState(false); //const [temAcesso, setTemAcesso] = useState(false);

	const location = useLocation();

	const navigate = useNavigate();

	useEffect(() => {
	  window.scrollTo(0, 0);
	}, [location]);

	return (
	 <>
	    <NavBar />
	    <Suspense fallback={<div className="container">Loading...</div>}>
	        <Routes>
	           <Route path="/" element={<Home />} />
	           <Route path="*" element={<Home />} />
	          {/* Tempos Liturgicos */}
	              <Route path="/musica-liturgica/musica/*" element={<MusicaCifra />} />
	              <Route path="/musica-liturgica/missa/*" element={<MusicaPage />} />
	              <Route path="/musica-liturgica/musica/*" element={<MusicaCifra />} />
	              <Route path="/musica-liturgica/tema/*" element={<TemaMusica />} />
	              <Route path="/musica-liturgica/tempo-comum" element={<TempoLiturgico tempoLiturgico={"tempo-comum"}/>} />
	              <Route path="/musica-liturgica/advento" element={<TempoLiturgico tempoLiturgico={"advento"}/>} />
	              <Route path="/musica-liturgica/quaresma" element={<TempoLiturgico tempoLiturgico={"quaresma"}/>} />
	              <Route path="/musica-liturgica/pascoa" element={<TempoLiturgico tempoLiturgico={"pascoa"}/>} />
	              <Route path="/musica-liturgica" element={<MusicaLiturgicaHome />} />
	              <Route path="/oracoes" element={<HomeOracoes/>}/>
	              <Route path="/oracoes/tema/*" element={<TemaOracoes/>}/>
	              <Route path="/oracoes/*" element={<Oracao/>}/>
	              <Route path="/documentos/*" element={<HomeSubsidios id_subsidio="1" tema="documentos" titulo="Documentos"/>}/>
	              <Route path="/roteiros/*" element={<HomeSubsidios id_subsidio="2" tema="roteiros/comentários para missa" titulo="Roteiros/Comentários para missa"/>}/>
	              <Route path="/rituais/*" element={<HomeSubsidios id_subsidio="3" tema="rituais" titulo="Rituais"/>}/>
	              <Route path="/artigos/" element={<HomeArtigos/>}/>
	              <Route path="/artigos/*" element={<LerArtigo/>}/>
	              // <Route path="/acesso-restrito/*" element={<HomeCadastro token={token}/>}/> // COMENTAR DEPOIS
	              <Route path="/acesso-restrito" element={<Login setTemAcesso={setTemAcesso} setToken={setToken}/>}/>
	              <Route
	                path="/acesso-restrito/home"
	                element={temAcesso? <HomeCadastro token={token}/>: <Login setTemAcesso={setTemAcesso} setToken={setToken}/>}
	              />	                
	              }
	        </Routes>
	    </Suspense>
	    <Footer />
	 </>
	);
	};


	export default App;