import React, {useState, useEffect} from 'react';
import line from "../../images/musica-liturgica/home/line.png";
import Divider from '@mui/material-next/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { NavLink, useNavigate } from 'react-router-dom';
// import ptBr from 'date-fns/locale/pt-br';
// with date-fns v3.x
import { pt } from 'date-fns/locale/pt';
import axios from 'axios';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';

// baseUrl
import {baseUrl} from "../../services/adalConfig.js";

import "./Subsidios.css";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function HomeSubsidios(props) {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [oracoes, setOracoes] = useState({});
    const [expandDict, setExpandDict] = useState({});
    const [pesquisasPossiveis, setPesquisasPossiveis] = useState([]);
    const [autocompleteValue, setAutocompleteValue] = useState('');

    useEffect(() => {
       function handleResize() {
         setWindowDimensions(getWindowDimensions());
       }
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        var getUrl = baseUrl + "/get-subsidios-agrupados?tipo_subsidio=" + props.id_subsidio;

        axios.get(getUrl)
            .then(resp => {
                setOracoes(resp.data);
                var tempDict = {};
                Object.keys(resp.data).map((item) => {
                    tempDict[item] = true;
                })
                setExpandDict(tempDict);
            });
    }, [props.id_subsidio]);

    useEffect(() => {
        var getUrl = baseUrl + "/subsidios-possiveis-pesquisa?tipo_subsidio=" + props.id_subsidio;

        axios.get(getUrl)
            .then(resp => {

                var temp = [];

                var sessao = resp.data["sessoes"];
                var sessaoKeys = Object.keys(sessao);
                for (let i = 0; i < sessaoKeys.length; i++) {
                    temp[sessaoKeys[i]] = "scrollTo"
                }

                var links = resp.data["links"];
                var linkKeys = Object.keys(links);
                for (let i = 0; i < linkKeys.length; i++) {
                    temp[linkKeys[i]] = links[linkKeys[i]];
                }

                setPesquisasPossiveis(temp);
            });
    }, [props.id_subsidio]);

    function changeExpansion(chave) {
        var newValue = !expandDict[chave];
        var tempDict = {...expandDict};
        tempDict[chave] = newValue;
        setExpandDict(tempDict);
    }

    return (<HomeSubsidiosDesktop
                isMobile={window.innerWidth <= 768}
                oracoes={oracoes} 
                expandDict={expandDict} 
                changeExpansion={changeExpansion} 
                pesquisasPossiveis={pesquisasPossiveis} 
                tema={props.tema} 
                titulo={window.innerWidth <= 768 && props.titulo == "Roteiros/Comentários para missa"? "Roteiros para missa" :props.titulo}
                autocompleteValue={autocompleteValue}
                setAutocompleteValue={setAutocompleteValue}
            />);
}

function HomeSubsidiosDesktop(props) {

    const navigate = useNavigate();
    const [value, setValue] = useState("");

    var oracoes = Object.keys(props.oracoes).map((chave, index) => {
        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="9">
                <div class="musica-home-header" style={index == 0? {marginTop: "0px", marginBottom: "20px"} : {marginTop: "20px", marginBottom: "20px"}}>
                    <Divider sx={{
                        "&::before, &::after": {
                        borderColor: "#bc1200",
                        },
                    }}>
                        <div style={{display: "flex", alignItems: "center"}}>
                        <ListItemButton onClick={() => props.changeExpansion(chave)} style={{display: "flex", justifyContent: "center"}}>
                                <p style={{fontSize: "25px"}} id={chave}>{chave}</p>
                                {props.expandDict[chave] ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        </div>
                    </Divider>
                </div>
                <Collapse in={props.expandDict[chave]} timeout="auto" unmountOnExit style={{width: "60%"}}>
                    <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
            {props.oracoes[chave].map((item, index) => {
                return (<li class="home-musicas-item-list"><a href={item.caminho_arquivo} target="_blank" id={item.nome_documento} style={{color: "black", textDecoration: "none"}}>{item.nome_documento}</a></li>)
            })
            }
            </ul>   
                </Collapse>
            </div>
    )})

    function highlightElement(el) {
        el.scrollIntoView({ behavior: 'smooth' , block: "center", inline: "center"});
        const innerText = el.innerText;
        // Create a new string with the underlined text 
        const underlinedText = "<u>" + el.innerText + "</u>"; 

        // Set the innerHTML of the output element to the underlined text 
        el.innerHTML = underlinedText; 

        setTimeout(() => {
                el.innerHTML = innerText;
            }, 1000);
        
    }

    var witdhAutocomplete;
    if (props.isMobile) {
        witdhAutocomplete = "90%";
    } else {
        witdhAutocomplete = "400px";
    }

    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
            <div style={props.isMobile? {marginTop: "0"}: {}} class="musica-home-header">
                <Divider sx={{
                    "&::before, &::after": {
                    borderColor: "#bc1200",
                    },
                }}>
                    {props.titulo}
                </Divider>
            </div>
            <div style={{width: witdhAutocomplete, height: "100px", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <Autocomplete
                    inputValue={props.autocompleteValue}
                    onChange={(event, newValue) => {
                        if (newValue == null || newValue == "") return;
                        var element = document.getElementById(newValue);
                        if (props.pesquisasPossiveis[newValue] == "scrollTo") {
                            highlightElement(element);
                        } else {
                            element.click();
                        }
                        props.setAutocompleteValue("");
                    }}
                    options={Object.keys(props.pesquisasPossiveis)}
                    renderInput={(params) => <TextField {...params} label={"Pesquisar todos os " + props.tema} />}
                />
            </div>
            <div style={{width: "100%"}}>
                {oracoes}
            </div>            
        </div>
    )
}

function HomeSubsidiosMobile(props) {
    
    const navigate = useNavigate();
    const [value, setValue] = useState("");

    var oracoes = Object.keys(props.oracoes).map((chave, index) => {
        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}  id="9">
                <div class="musica-home-header" style={index == 0? {marginTop: "0px", marginBottom: "20px"} : {marginTop: "20px", marginBottom: "20px"}}>
                    <Divider sx={{
                        "&::before, &::after": {
                        borderColor: "#bc1200",
                        },
                    }}>
                        <div style={{display: "flex", alignItems: "center"}}>
                        <ListItemButton onClick={() => props.changeExpansion(chave)} style={{display: "flex", justifyContent: "center"}}>
                                <p style={{fontSize: "25px"}}>{chave}</p>
                                {props.expandDict[chave] ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        </div>
                    </Divider>
                </div>
                <Collapse in={props.expandDict[chave]} timeout="auto" unmountOnExit style={{width: "60%"}}>
                    <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%"}}>
            {props.oracoes[chave].map((item, index) => {
                return (<li style={{ marginBottom: "7px"}} class="home-musicas-item-list"><a href={item.caminho_arquivo} style={{color: "black", textDecoration: "none"}}>{item.nome_documento}</a></li>)
            })
            }
            </ul>   
                </Collapse>
            </div>
    )})

        
    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
            <div class="musica-home-header" style={{marginTop: "20px"}}>
                <Divider sx={{
                    "&::before, &::after": {
                    borderColor: "#bc1200",
                    },
                }}>
                    Orações
                </Divider>
            </div>
            <div style={{width: "70%", height: "100px", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <Autocomplete
                    onChange={(event, newValue) => {
                        navigate(props.pesquisasPossiveis[newValue]);
                    }}
                    options={Object.keys(props.pesquisasPossiveis)}
                    renderInput={(params) => <TextField {...params} label="Pesquisar todas as orações" />}
                />
            </div>
            <div style={{width: "100%"}}>
                {oracoes}
            </div>            
        </div>
    )
}