import React, {useState, useEffect} from 'react';
import line from "../../images/musica-liturgica/home/line.png";
import Divider from '@mui/material-next/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { NavLink, useNavigate } from 'react-router-dom';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import StarsIcon from '@mui/icons-material/Stars';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import TagIcon from '@mui/icons-material/Tag';
// import ptBr from 'date-fns/locale/pt-br';
// with date-fns v3.x
import { pt } from 'date-fns/locale/pt';
import axios from 'axios';

// baseUrl
import {baseUrl} from "../../services/adalConfig.js";

import "./Artigos.css";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function ArtigosHome(props) {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [artigosDestaque, setArtigosDestaque] = useState([]);
    const [artigosRecentes, setArtigosRecentes] = useState([]);
    const [artigosPorTema, setArtigosPorTema] = useState([]);
    const [numArtigosMostrar, setNumArtigosMostrar] = useState([]);
   
    useEffect(() => {
       function handleResize() {
         setWindowDimensions(getWindowDimensions());
       }
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {

        var getUrl = baseUrl + "/get-artigos-destaque";

        axios.get(getUrl)
            .then(resp => {
                setArtigosDestaque(resp.data); 
            });
    }, []);

    useEffect(() => {

        var getUrl = baseUrl + "/get-artigos-recentes";

        axios.get(getUrl)
            .then(resp => {
                setArtigosRecentes(resp.data); 
            });
    }, []);

    useEffect(() => {

    	var getUrl = baseUrl + "/get-artigos-por-categoria";

        axios.get(getUrl)
            .then(resp => {

            	var numeroArtigos = Object.keys(resp.data);
            	var tempList = [];
            	for (let i = 0; i < numeroArtigos.length; i++) {
            		tempList.push(3);
            	}

            	setNumArtigosMostrar(tempList);
                setArtigosPorTema(resp.data); 
            });

    }, []);

    function increaseNumArtigos(index) {
    	var tempList = [...numArtigosMostrar];

    	tempList[index] += 3;

    	setNumArtigosMostrar(tempList);
    }

    function scrollTo(index) {
        var element = document.getElementById(index);
        element.scrollIntoView({ behavior: 'smooth' , block: "center", inline: "center"});
    }

    if (window.innerWidth <= 768) {
       return (<ArtigosHomeMobile artigosDestaque={artigosDestaque} artigosPorTema={artigosPorTema} increaseNumArtigos={increaseNumArtigos} numArtigosMostrar={numArtigosMostrar} scrollTo={scrollTo}/>);
    } else {
       return (<ArtigosHomeDesktop artigosDestaque={artigosRecentes} artigosPorTema={artigosPorTema} increaseNumArtigos={increaseNumArtigos} numArtigosMostrar={numArtigosMostrar} scrollTo={scrollTo}/>);
    }
}

function ArtigoGrande(props) {
	
	if (props.artigo) {
		return (
		<div className="newest-ones-prime">
			<a href={`/artigos/${props.artigo.url}`}>
				<picture className="newest-ones-prime__picture">
					<img style={{width: "100%", height: "100%", objectFit: "cover"}} loading="lazy" src={`data:image/png;base64,${props.artigo.imagem}`}/>
				</picture>
				<div className="newest-ones-prime__text">
					<div className="newest-ones-prime__caption">
						<span className="newest-ones-prime__date">{props.artigo.dataCriacao}</span>
					</div>
					<h3>{props.artigo.nome}</h3>
				</div>
			</a>
		</div>
		)
	} else {
		return <div></div>;
	}

	
}

function ArtigoMedio(props) {
	if (props.artigo) {
		return (
		<a className="home-artigo-medio-grid" href={`/artigos/${props.artigo.url}`}>
			<img style={{width: "100%", height: "100%", objectFit: "cover", objectPosition: "top"}} loading="lazy" src={`data:image/png;base64,${props.artigo.imagem}`}/>
			<div>
				<span className="data-artigo-medio">{props.artigo.dataCriacao}</span>
				<p style={{fontWeight: "700", lineHeight: "25px", fontSize: "calc(1.5rem + 0.1vw)"}}>{props.artigo.nome}</p>
				<p className="artigos-home-medio-resumo">{props.artigo.resumo}</p>
			</div>
		</a>
		)
	} else {
		return <div></div>;
	}
}

function ArtigoMiniatura(props) {
	if (props.artigo) {
		return (
		<a className="home-artigo-pequeno" href={`/artigos/${props.artigo.url}`}>
			<img style={{width: "100%", height: "100%", objectFit: "cover", objectPosition: "top"}} loading="lazy" src={`data:image/png;base64,${props.artigo.imagem}`}/>
			<div>
				<p style={{fontWeight: "700", lineHeight: "25px", fontSize: "calc(1.0rem + 0.1vw)"}}>{props.artigo.nome}</p>
				<p className="artigos-home-miniatura-resumo">{props.artigo.resumo}</p>
			</div>
		</a>
		)
	} else {
		return <div></div>;
	}
}

function ArtigosHomeDesktop(props) {

    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
            <div class="musica-home-header">
                <Divider sx={{
                    "&::before, &::after": {
                    borderColor: "#bc1200",
                    },
                }}>
                    Artigos
                </Divider>
            </div>
            <div style={{display: "grid", gridTemplateColumns: "3fr 1.5fr", width: "90%", marginTop: "18px", gap: "30px"}}>
            	<div>
            		<div style={{display: "flex", alignItems: "center", gap: "8px", marginBottom: "5px"}}>
		            	<NewReleasesIcon/>
		            	<p>Mais recentes</p>
		            </div>
            	<ArtigoGrande artigo={props.artigosDestaque[0]}/>
            	<div className="artigos-home-medio">
	            	<ArtigoMedio artigo={props.artigosDestaque[1]}/>
	            	<div style={{display: "flex", alignItems: "center"}}>
	            		<div style={{width: "100%", height: "1px", backgroundColor: "#bc1200"}}></div>
	            	</div>
	            	<ArtigoMedio artigo={props.artigosDestaque[2]}/>
            	</div>
	        	</div>
	        	<div>
	        		<div style={{display: "flex", alignItems: "center", gap: "8px", marginBottom: "5px"}}>
		            	<StarsIcon/>
		            	<p>Mais acessados</p>
		            </div>
		            <div style={{display: "flex", flexDirection: "column", gap: "30px"}}>
		        		<ArtigoMiniatura artigo={props.artigosDestaque[3]}/>
		        		<ArtigoMiniatura artigo={props.artigosDestaque[4]}/>
		        		<ArtigoMiniatura artigo={props.artigosDestaque[5]}/>
		        		<ArtigoMiniatura artigo={props.artigosDestaque[6]}/>
		        		<ArtigoMiniatura artigo={props.artigosDestaque[7]}/>
		        		<ArtigoMiniatura artigo={props.artigosDestaque[8]}/>
		        	</div>
	        	</div>
            </div>
            <div style={{display: "grid", gridTemplateColumns: "3fr 1.5fr", width: "90%", gap: "30px", marginTop: "15px"}}>
            	<div>
            	{
            		Object.keys(props.artigosPorTema).map((item, index) => {

            			var artigos = props.artigosPorTema[item].slice(0,props.numArtigosMostrar[index]).map( (temp_artigo) => {
            				return (<ArtigoMedio artigo={temp_artigo}/>)
            			});
            			
            			return(
            				<div style={{marginTop: "18px"}}>
	            				<div style={{display: "flex", alignItems: "center"}} id={item}>
	            					<LabelImportantIcon/>
	            					<p>{item}</p>
	            				</div>
	            				<div style={{display: "grid", gridAutoRows: "160px", gap: "20px", marginTop: "15px"}}>
	            					{artigos}
	            				</div>
	            				<p onClick={() => props.increaseNumArtigos(index)} class="home-call-to-action" style={{marginBottom: "0px", marginTop: "15px", color: "rgb(188, 18, 0)", width: "fit-content"}}>
				            		<b>Ver mais </b>
				            	</p>
            				</div>
            			)
            		})
            	}
            	</div>
            		<div style={{marginTop: "18px"}}>
            			<div style={{display: "flex", alignItems: "center"}}>
	            			<TagIcon/>
	            			<p>Temas</p>
            			</div>
            			<div style={{marginTop: "15px"}}>
            				<ul style={{display: "flex", flexDirection: "column", justifyContent: "space-between", width: "90%", marginLeft: "23px"}}>
            				{
            					Object.keys(props.artigosPorTema).map((item, index) => {
            						return(
            							<li class="musica-page-musica-item" onClick={() => props.scrollTo(item)}>{item}</li>
            						)
            					})
            				}
            				</ul>
            			</div>
            		</div>
            	<div>
            	</div>
            </div>
        </div>
    )
}

function ArtigoGrandeMobile(props) {
    
    if (props.artigo) {
        return (
        <div className="newest-ones-prime">
            <a href={`/artigos/${props.artigo.url}`}>
                <picture className="newest-ones-prime__picture">
                    <img style={{width: "100%", height: "100%", objectFit: "cover"}} loading="lazy" src={`data:image/png;base64,${props.artigo.imagem}`}/>
                </picture>
                <div className="newest-ones-prime__text">
                    <div className="newest-ones-prime__caption">
                        <span className="newest-ones-prime__date">{props.artigo.dataCriacao}</span>
                    </div>
                    <h3>{props.artigo.nome}</h3>
                </div>
            </a>
        </div>
        )
    } else {
        return <div></div>;
    }   
}
// <span className="data-artigo-medio">{props.artigo.dataCriacao}</span>
function ArtigoMedioMobile(props) {
    if (props.artigo) {
        return (
        <a href={`/artigos/${props.artigo.url}`} class="home-artigos-carousel-format" style={{width: "100%", color: "black", textDecoration: "none"}}>
            <div class="home-artigos-card" style={{height: "100%", width: "100%", justifyContent: "flex-start"}}>
                <img draggable="false" src={`data:image/png;base64,${props.artigo.imagem}`} style={{width: "90%", height: "160px", objectFit: "cover", objectPosition: "top", marginTop: "15px", cursor: "pointer"}}/>
                <h3 style={{width: "90%", fontSize: "18px", marginTop: "14px", cursor: "pointer"}}>{props.artigo.nome}</h3>
                <p style={{width: "90%", "-webkit-line-clamp": "5", "-webkit-box-orient": "vertical", lineHeight: "21px", overflow: "hidden", display: "-webkit-box", marginTop: "6px", "font-size": "calc(1.0rem + 0vw)"}}>{props.artigo.resumo}</p>
            </div>
        </a>
        )
    } else {
        return <div></div>;
    }
}

function ArtigosHomeMobile(props) {
    
    return <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
        <div style={{width: "90%", marginTop: "0px"}}>
                <div class="musica-home-header" style={{width: "100%", marginTop: "0px"}}>
                    <Divider sx={{
                        "&::before, &::after": {
                        borderColor: "#bc1200",
                        },
                    }}>
                        Artigos
                    </Divider>
                </div>
        </div>
        <div style={{width: "90%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px"}}>
            <ArtigoGrandeMobile artigo={props.artigosDestaque[0]}/>
            <div style={{width: "100%", display: "grid", gridAutoRows: "350px", gap: "18px",    marginTop: "15px"}}>
                {
                    props.artigosDestaque.slice(1,props.artigosDestaque.length).map((item, index) => {
                            return (<ArtigoMedioMobile artigo={item}/>) 
                    })
                }
            </div>
        </div>
    </div>
}