import React, {useState, useEffect, useRef} from 'react';
import line from "../../images/musica-liturgica/home/line.png";
import Divider from '@mui/material-next/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { NavLink, useNavigate } from 'react-router-dom';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import StarsIcon from '@mui/icons-material/Stars';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import TagIcon from '@mui/icons-material/Tag';
import Box from '@mui/material/Box';
import ShareIcon from '@mui/icons-material/Share';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
// import ptBr from 'date-fns/locale/pt-br';
// with date-fns v3.x
import { pt } from 'date-fns/locale/pt';
import axios from 'axios';

// baseUrl
import {baseUrl} from "../../services/adalConfig.js";

import "./Artigos.css";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function LerArtigo(props) {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [artigo, setArtigo] = useState();
   
    useEffect(() => {
       function handleResize() {
         setWindowDimensions(getWindowDimensions());
       }
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {

        var url = window.location.href;

        var l = url.split("/");
        var url_missa = l[l.length - 1];

        var url = baseUrl + "/get-artigo-by-url?url="+url_missa;
        console.log("Url is");
        console.log(url);

        axios.get(url)
            .then(resp => {
            	if (resp.data != null && resp.data != "") {
					console.log("Got response");
            		console.log(resp.data);
            		setArtigo(resp.data);
            	}
            });

    }, []);

    function scrollTo(index) {
        var element = document.getElementById(index);
        element.scrollIntoView({ behavior: 'smooth' , block: "center", inline: "center"});
    }

    if (window.innerWidth <= 768) {
       return (<LerArtigoMobile artigo={artigo}/>);
    } else {
       return (<LerArtigoDesktop artigo={artigo}/>);
    }
}

function ArtigoGrande(props) {
	
	if (props.artigo) {
		return (
		<div className="newest-ones-prime">
			<a href={`/artigos/${props.artigo.url}`}>
				<picture className="newest-ones-prime__picture">
					<img style={{width: "100%", height: "100%", objectFit: "cover"}} loading="lazy" src={`data:image/png;base64,${props.artigo.imagem}`}/>
				</picture>
				<div className="newest-ones-prime__text">
					<div className="newest-ones-prime__caption">
						<span className="newest-ones-prime__date">{props.artigo.dataCriacao}</span>
					</div>
					<h3>{props.artigo.nome}</h3>
				</div>
			</a>
		</div>
		)
	} else {
		return <div></div>;
	}

	
}

function ArtigoMedio(props) {
	if (props.artigo) {
		return (
		<a className="home-artigo-medio-grid" href={`/artigos/${props.artigo.url}`}>
			<img style={{width: "100%", height: "100%", objectFit: "cover", objectPosition: "top"}} loading="lazy" src={`data:image/png;base64,${props.artigo.imagem}`}/>
			<div>
				<span className="data-artigo-medio">{props.artigo.dataCriacao}</span>
				<p style={{fontWeight: "700", lineHeight: "25px", fontSize: "calc(1.5rem + 0.1vw)"}}>{props.artigo.nome}</p>
				<p className="artigos-home-medio-resumo">{props.artigo.resumo}</p>
			</div>
		</a>
		)
	} else {
		return <div></div>;
	}
}

function ArtigoMiniatura(props) {
	if (props.artigo) {
		return (
		<a className="home-artigo-pequeno" href={`/artigos/${props.artigo.url}`}>
			<img style={{width: "100%", height: "100%", objectFit: "cover", objectPosition: "top"}} loading="lazy" src={`data:image/png;base64,${props.artigo.imagem}`}/>
			<div>
				<p style={{fontWeight: "700", lineHeight: "25px", fontSize: "calc(1.0rem + 0.1vw)"}}>{props.artigo.nome}</p>
				<p className="artigos-home-miniatura-resumo">{props.artigo.resumo}</p>
			</div>
		</a>
		)
	} else {
		return <div></div>;
	}
}

function LerArtigoDesktop(props) {

	const [documentContent, setDocumentContent] = useState('');
	const [open, setOpen] = useState(false);

	function handleCopy() {
		navigator.clipboard.writeText(window.location.href);
		setOpen(true);
	}

	const handleClose = () => {
		setOpen(false);
	}

	if (props.artigo == null) return <></>;
	// atob(props.artigo.artigo_rtf)
    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
        	<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
		        <Alert
		          onClose={handleClose}
		          severity="success"
		          variant="filled"
		          sx={{ width: '100%' }}
		        >
		         	Link copiado para a área de transferência!
		        </Alert>
	     	</Snackbar>
            <div id="primeiro" style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
              <div style={{width: "70%", display: "flex", flexDirection: "column", gap: "15px"}}>
              	<p style={{fontSize: "38px", fontFamily: 'aktiv-grotesk', fontWeight: "normal"}} ><b>{props.artigo.nome}</b></p>
              	<div className="artigo-tamanho-fonte">
              	<p style={{color: "rgba(0,0,0,.6)"}}>{props.artigo.resumo}</p>
              	</div>
              	<div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              		<span className="artigo-tamanho-fonte"><p><u>{props.artigo.autor}</u></p></span>
              		<Box
					    sx={{
					        display: 'flex',
					        flexDirection: 'column',
					        alignItems: 'center',
					        border: '1px solid',
					        borderColor: 'divider',
					        borderRadius: 2,
					        bgcolor: 'background.paper',
					        color: 'text.secondary',
					        '& svg': {
					            m: 1,
					        },
					    }}
					    style={{display: "flex", justifyContent: "center",  flexDirection: "row", cursor: "pointer", "padding": "5px"}}
					    onClick={() => handleCopy()}
				    >
			    		<ShareIcon style={{margin: "0px 4px 0px 0px"}} />
			    		<p style={{fontSize: "14px", color: "rgba(0, 0, 0, 0.6)"}}>Compartilhar</p>
			    	</Box>
              	</div>
              </div>
              <div style={{ width: "70%", height: "50px", display: "flex", alignItems: "center"}}>
                    <Divider style={{width: "100%"}} sx={{
                    borderColor: "#bc1200"
                    }} />
                </div>
		      <div id="segundo" className="artigo-tamanho-fonte" style={{width: "70%"}} dangerouslySetInnerHTML={{__html: props.artigo.artigo_html}}/>
		    </div>
        </div>
    )
}

function LerArtigoMobile(props) {
    
    const [documentContent, setDocumentContent] = useState('');
	const [open, setOpen] = useState(false);

	function handleCopy() {
		navigator.clipboard.writeText(window.location.href);
		setOpen(true);
	}

	function handleShare() {
		navigator.share({
	    title: props.artigo.nome,
	    url: window.location.href
	  })
	  .then(() => console.log('Successful share'))
	  .catch(error => console.log('Error sharing:', error));
	}

	const handleClose = () => {
		setOpen(false);
	}

	if (props.artigo == null) return <></>;
	// atob(props.artigo.artigo_rtf)
    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
        <div style={{width: "90%", marginTop: "0px"}}>
            <div id="primeiro" style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
              <div style={{width: "100%", display: "flex", flexDirection: "column", gap: "15px"}}>
              	<p style={{fontSize: "30px", fontFamily: 'aktiv-grotesk', fontWeight: "normal"}} ><b>{props.artigo.nome}</b></p>
              	<div className="artigo-tamanho-fonte">
              	<p style={{color: "rgba(0,0,0,.6)"}}>{props.artigo.resumo}</p>
              	</div>
              	<div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              		<span className="artigo-tamanho-fonte"><p><u>{props.artigo.autor}</u></p></span>
              		<Box
					    sx={{
					        display: 'flex',
					        flexDirection: 'column',
					        alignItems: 'center',
					        border: '1px solid',
					        borderColor: 'divider',
					        borderRadius: 2,
					        bgcolor: 'background.paper',
					        color: 'text.secondary',
					        '& svg': {
					            m: 1,
					        },
					    }}
					    style={{display: "flex", justifyContent: "center",  flexDirection: "row", cursor: "pointer", "padding": "5px"}}
					    onClick={() => handleShare()}
				    >
			    		<ShareIcon style={{margin: "0px 4px 0px 0px"}} />
			    		<p style={{fontSize: "14px", color: "rgba(0, 0, 0, 0.6)"}}>Compartilhar</p>
			    	</Box>
              	</div>
              </div>
              <div style={{ width: "100%", height: "50px", display: "flex", alignItems: "center"}}>
                    <Divider style={{width: "100%"}} sx={{
                    borderColor: "#bc1200"
                    }} />
                </div>
		      <div id="segundo" className="artigo-tamanho-fonte" style={{width: "100%"}} dangerouslySetInnerHTML={{__html: props.artigo.artigo_html}}/>
		    </div>
        </div>
        </div>
    )
}